import "./Dashboard.footer.css";

const DashboardFooter = () => {
  return (
    <div className="dashboard__footer">
      <section className="dashboard__footer__section__one"></section>
      <section className="dashboard__footer__section__two">
        <p>&copy; Copyright Olaike 2024</p>
        <p>Terms | Privacy | Legal</p>
      </section>
    </div>
  );
};

export default DashboardFooter;
